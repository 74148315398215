<template>
  <b-row>
    <b-col cols="12">
      <form-wizard-vertical :kycstange="kycStange" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import FormWizardVertical from './KycWizardForm.vue'

export default {
  components: {
    BRow,
    BCol,
    FormWizardVertical,
  },
  data() {
    return {
      kycStange: 0,
    }
  },
  created() {
    this.getKycState()
  },
  methods: {
    getKycState() {
      this.$sessionData.setContext(this)
      if (this.$sessionData.validateSession()) {
        this.$sessionData.updateKycSession().then(userData => {
          if ((!userData.data.kyc.sourceDocument1 || !userData.data.kyc.sourceDocument2) && userData.data.kyc.name !== '') {
            this.kycStange = 1
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
